<template>
    <button :class="enabled ? className : className + ' disabled'">
      <slot></slot>
    </button>
</template>
<script>
export default {
    props:{
        enabled:{
            type:Boolean,
            default:true
        },
        className:{
            type:String,
            default:'button primary pl8 pr8 '
        }
    },
    setup(/* props */){
    }
}
</script>
<style lang="postcss" >
    
    .primary {

        text-decoration: none;
        color: #ffffff;
        background-color: #2cbeff;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 10px;
        border: none;
        min-width: 12ch;
        padding: 1em;
        
        &:hover{
            background-color: #2f3a3f;
            color: #F2F2F2;
        }
        &.disabled{
            opacity: .6;
            pointer-events: none;

            &:hover{
                color: #ffffff;
                background-color: #2cbeff;
            }
        }
    }
    .secondary{

        background-color: #2f3a3f;
        color: #F2F2F2;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 10px;
        border: none;
        min-width: 12ch;
        padding: 1em;

        &:hover{
            background-color: #2cbeff;
            color: #2f3a3f;
        }

        &.disabled{
            opacity: .6;
            pointer-events: none;
            &:hover{
                background-color: #2f3a3f;
                color: #F2F2F2;
            }
        }
    }
    .arrow {

        text-decoration: none;
        color: #000000;
        background-color: #ffffff;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 10px;
        border: none;
        min-width: 12ch;
        padding: 1em;
        outline:none;

        &:hover{
            background-color: #2f3a3f;
            color: #F2F2F2;
        }

        &.disabled{
            opacity: .6;
            pointer-events: none;
            &:hover{
                color: #ffffff;
                background-color: #2cbeff;
            }
        }
    }

    .pagination{
        text-decoration: none;
        color: #2cbeff;
        background-color: #f4f4f4;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 10px;
        border: none;
        padding: 1em;
        min-width: 5ch;

        &:hover {
            color: #000000;
            
        }
        
    }

</style>
