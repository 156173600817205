<template>
    <div class="resizable-column-container" ref="columnContainer" >
        <slot></slot>
    </div>
</template>
<script>
import{ ref, onMounted, onUnmounted} from 'vue'
export default {
    emits: ['total-size'], 
    props:{
        maxCol:{
            type:Number,
            default:4
        }
    },
    setup(_, {emit}) {

        const columnContainer = ref(null)
        
        const totalWidth = ref(null)

        const onWindowResize = () =>{
            totalWidth.value = columnContainer.value.getBoundingClientRect().width
            emit('total-size', totalWidth.value)
        }

        onMounted(() => {
            totalWidth.value = columnContainer.value.getBoundingClientRect().width
            window.addEventListener('resize', onWindowResize)
            onWindowResize()
            emit('total-size', totalWidth.value)
        });
        onUnmounted(() => {
            window.removeEventListener('resize', onWindowResize)
        });

        return {
            totalWidth,
            columnContainer
        }

    }
}
</script>
<style>
#column2 {
    position: relative;
}

</style>