<template>
    <div 
        :class="className" 
        :id="'column-'+columnIndex"
        ref="content"
    >
        <slot></slot>        
        <div 
            v-if="resizable"
            class="column-resizer"
            @mousedown="onResizerMouseDown"
            ref="handle"
        ></div>
    </div> 
</template>
<script>
import {ref} from 'vue'
import {disableElementUserSelect,enableElementUserSelect} from '../utils/ColumnsResizer'
export default {
    emits: ['column-resize'], 
    props:{
        className:{
            type:String,
            default:'layout-list-container'
        },
        columnIndex:{
            type: Number,
            default: 0
        },
        resizable:{
            type: Boolean,
            default: true
        }
    },
    setup(props, context){

        
        const handle = ref(null)
        const content = ref(null)
        
        const onResizerMouseDown = (e)=>{
            document.body.addEventListener('mouseup', onMouseUp)
            document.body.addEventListener('mouseleave', onMouseUp)
            document.body.addEventListener('mousemove', resizeOnMouseMove)
            e.target.classList.add('dragging')
            disableElementUserSelect()
        }
       
        const resizeOnMouseMove = (e)=>{

            const { movementX } = e
            const actualWidth = getComputedStyle(content.value).width 
            context.emit('column-resize', {
                diff:movementX, 
                index:props.columnIndex, 
                currentWidth:parseInt(actualWidth)
            })
           
        }

        const onMouseUp = ()=>{
            document.body.removeEventListener('mouseup', onMouseUp)
            document.body.removeEventListener('mouseleave', onMouseUp)
            document.body.removeEventListener('mousemove', resizeOnMouseMove)
            handle.value.classList.remove('dragging')
            enableElementUserSelect()
        } 

        return{
            onResizerMouseDown,
            handle,
            content
        }
    }
}
</script>
