<template>
    <div v-if="!editing" :class="className">
        <div class="flex-container">
            <input 
                type="submit" 
                value="Abilita Modifiche" 
                class="w100 noborder nopadding bebas f24" 
                @click.prevent="enableEdit"
            >
        </div>
    </div>
    <div v-else :class="className">
        <div class="flex-container">
            <input
                v-if="buttons.disableBtn" 
                type="reset" 
                :value="buttons.disableBtnMessage" 
                class="w100 noborder nopadding bebas" 
                @click.prevent="disableEdit"
            >
            <input 
                v-if="buttons.submitBtn"
                type="submit" 
                :value="buttons.submitBtnMessage" 
                class="w100 noborder nopadding bebas" 
                @click.prevent="submit"
            >
            <input 
                v-if="buttons.deleteBtn"
                type="reset" 
                :value="buttons.deleteBtnMessage" 
                class="w100 noborder nopadding bebas" 
                @click.prevent="onDeleteClick"
            >
        </div>
    </div>
</template>
<script>

export default {
    emits: ['edit-enable', 'edit-disable','submit-form', 'delete-press'],
    props:{
        editing:{
            type:Boolean,
            default:true
        },
        className:{
            type:String,
            default:'layout-content-footer'
        },
        buttons:{
            type:Object,
            default:()=>{
                return{
                    disableBtn:true,
                    disableBtnMessage:'Annulla',
                    submitBtn:true,
                    submitBtnMessage:'Salva',
                    deleteBtn:true,
                    deleteBtnMessage:'Elimina',
                }
                
            }
        }
    },
    setup(props, context){
        
        const enableEdit = ()=>{
            context.emit('edit-enable')
        }
        const disableEdit = async ()=> {
           context.emit('edit-disable') 
        }
        const submit = async ()=> {
           context.emit('submit-form') 
        }
        const onDeleteClick = async ()=> {
           context.emit('delete-press') 
        }
        return{
            onDeleteClick,
            enableEdit,
            disableEdit,
            submit
        } 
    }
}
</script>
<style lang="postcss">
    .layout-content-footer.abs{
        bottom: 0;
        width: 100%;
    }
</style>